import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import { Navigation } from '../../common/navigation'
import { GlobalHelmet } from '../../common/seo/globalHelmet'
import { Footer } from '../../common/footer'
import { CMSWrapper } from '../../components/cmsWrapper'
import { AREA_CARE_PATH } from '../../config/constants'
import { Button } from '../../common/button'

const localStorageKey = 'JukeboxTop10'

export default function Testergebnis({ location }) {
  const [top10Stations, setTop10Stations] = React.useState()
  const [showSavedAlert, setShowSavedAlert] = React.useState(false)

  const stationsData = useStaticQuery(graphql`
    {
      allStation {
        nodes {
          title
          introText
          slug
          stationId
          hasVacancies
        }
      }
    }
  `)

  React.useEffect(() => {
    let stations
    const top10StationsFromStorage = window.localStorage.getItem(
      localStorageKey
    )
    const top10StationsFromQueryParams = location.search.slice(1)

    if (top10StationsFromQueryParams) {
      const top10StationsFromQuery = top10StationsFromQueryParams.split('&')
      stations = stationsData.allStation.nodes.filter((station) =>
        top10StationsFromQuery.includes(station.stationId)
      )
      window.localStorage.setItem(localStorageKey, JSON.stringify(stations))
    } else if (top10StationsFromStorage) {
      stations = JSON.parse(top10StationsFromStorage)
    } else {
      if (location.state && location.state.matchingResult) {
        stations = location.state.matchingResult
        window.localStorage.setItem(localStorageKey, JSON.stringify(stations))
      }
    }
    if (!stations) {
      /**
       * Redirect to jukebox game start page if no matching stations have been found,
       * e.g. if the user tries to open the result page manually while he has never
       * answered the jukebox questions
       */
      navigate('/pflege/spielen', { replace: true })
    } else {
      setTop10Stations(stations)
    }
  }, [location.search, location.state, stationsData.allStation.nodes])

  // TODO: Read stations from url params (=> if user saved top 10 as link)
  const saveTop10AsLink = React.useCallback(() => {
    let link = `${location.href}?`
    top10Stations.forEach(
      (station, i) =>
        (link += `${station.stationId}${
          i < top10Stations.length - 1 ? '&' : ''
        }`)
    )
    navigator.clipboard.writeText(link)
    setShowSavedAlert(true)
    setTimeout(() => {
      setShowSavedAlert(false)
    }, 2000)
  }, [top10Stations, location.href])

  const deleteData = React.useCallback(() => {
    window.localStorage.removeItem(localStorageKey)
    window.localStorage.removeItem('current-question')
    window.localStorage.removeItem('JukeboxAnswerValues')
    setTop10Stations(undefined)
    navigate(`${AREA_CARE_PATH}/spielen`, { replace: true })
  }, [])

  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Testergebnis" />
      <main>
        <Navigation />

        <header className="bg-img-red">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-12 text-center">
              <img
                src="/img/handlettering/handlettering-und-es-hat-zooom-gemacht.svg"
                alt="Handlettering: Und es hat Zooom gemacht"
                width="400"
              />
            </div>

            <div className="col-md-5 col-12 text-center">
              <h3>Wer, wo, was willst du morgen sein?</h3>
              <p>Deine Matching-Ergebnisse aus der Jukebox</p>
            </div>
          </div>
        </header>

        <section id="stations" className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-8 offset-md-2 col-12">
              <ul className="link-list">
                {top10Stations &&
                  top10Stations.map((station, index) => (
                    <li
                      key={index}
                      className={`job-${
                        station.hasVacancies ? 'green' : 'red'
                      }`}
                    >
                      <h3 className="mb-0">
                        <Link
                          className="bg-dark-blue"
                          to={station.slug}
                          state={{
                            carouselTitle: 'Deine Top 10',
                            carouselSlugs: top10Stations.map(
                              (station) => station.slug
                            ),
                          }}
                        >
                          {station.title}
                        </Link>
                        <small>{station.introText}</small>
                      </h3>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <ul id="job-indicator" className="col-md-8 offset-md-2 col-12 mt-5">
            <li className="job-green">Wir haben eine freie Stelle</li>
            <li className="job-red">
              Wir haben zur Zeit leider keine freie Stelle
            </li>
          </ul>
        </section>

        <div className="row text-center mt-5 mb-5">
          <div className="col-md-10 offset-md-1 col-12 mt-2">
            <Button
              color="dark-blue"
              id="save-link"
              className="mt-0 clipboard"
              onClick={saveTop10AsLink}
            >
              TOP 10 als Link speichern
            </Button>
            {showSavedAlert && (
              <input
                type="text"
                id="copyAlert"
                value="Link gespeichert"
                style={{ position: 'absolute' }}
                readOnly
              />
            )}
            <p className="mt-2">
              um meinen Spielstand zu speichern und auch auf anderen Geräten
              weiterspielen zu können.
            </p>
          </div>

          <div className="col-md-10 offset-md-1 col-12 mt-2">
            <Button color="dark-blue" onClick={deleteData}>
              TOP 10 löschen
            </Button>
            <p className="mt-2">
              um deine Top Ten und deine Matching-Antworten zu löschen.
            </p>
          </div>
        </div>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
